<template>
  <div :class="myslh=='elder'?'old':''">
    <ul class="content">
      <!-- <li>
        <p class="txt">信访人:</p>
        <input class="c_txt" type="text"  v-model="name">
      </li> -->
      <li>
        <p class="txt">代办人数:</p>
        <input class="c_txt" type="text"  v-model="people_number">
      </li>
      <!-- <div style="height: 8px; background: whitesmoke"></div> -->
      <!-- <li>
        <p class="txt">证件号码:</p>
        <input class="c_txt" type="text"  v-model="id_card">
      </li>
      <li>
        <p class="txt">电话号码:</p>
        <input class="c_txt" type="text"  v-model="phone">
      </li> -->
      <li>
        <p class="txt">登记单位:</p>
        <p class="c_txt" @click="showPopup3">{{registered_unit}}</p>
      </li>
      <li>
        <p class="txt">信访日期:</p>
        <p class="c_txt" @click="showPopup1">{{petition_date}}</p>
      </li>
      <li>
        <p class="txt">登记日期:</p>
        <p class="c_txt" @click="showPopup2">{{registration_date}}</p>
      </li>
      <!-- <div style="height: 8px; background: whitesmoke"></div> -->
      <li>
        <p class="txt">问题发生地:</p>
        <p class="c_txt" @click="showPopup">{{street_name}}，{{ problem_happen }}</p>
      </li>
      <!-- <div style="height: 8px; background: whitesmoke"></div> -->
      <p class="text">信访人地址:</p>
      <input class="c_text" type="text" v-model="petitioner_address">
      <p class="text">诉求内容:</p>
      <input class="c_text" type="text" v-model="main_appeal">
    </ul>
    <div class="tijiao" @click="from">确认修改</div>
    <van-popup v-model="show" position="bottom" :style="{ height: '45%' }"
      ><van-picker
        title="标题"
        show-toolbar
        :columns="columns"
        value-key="name"
        @confirm="onConfirm"
        @cancel="onCancel"
    /></van-popup>
    <van-popup v-model="show3" position="bottom" :style="{ height: '45%' }"
      ><van-picker
        title="标题"
        show-toolbar
        :columns="columns1"
        value-key="name"
        @confirm="onConfirm3"
        @cancel="onCancel3"
    /></van-popup>
    <van-popup v-model="show1" position="bottom" :style="{ height: '45%' }"
      ><van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择年月日"
        :min-date="minDate"
        :max-date="maxDate"
        @confirm="onConfirm1"
        @cancel="onCancel1"
    /></van-popup>
    <van-popup v-model="show2" position="bottom" :style="{ height: '45%' }"
      ><van-datetime-picker
        v-model="currentDate1"
        type="date"
        title="选择年月日"
        :min-date="minDate1"
        :max-date="maxDate1"
        @confirm="onConfirm2"
        @cancel="onCancel2"
    /></van-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { Popup } from "vant";
Vue.use(Popup);
import { Picker } from "vant";
Vue.use(Picker);
import { Dialog } from "vant";
// import { Toast } from "vant";
import { DatetimePicker } from "vant";
Vue.use(DatetimePicker);
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data() {
    return {
      list: [],
      status:'',
      name:"",
      people_number:"",
      id_card:"",
      phone:"",
      registered_unit:"",
      petition_date:"",
      registration_date:"",
      registration_date:"",
      petitioner_address:"",
      street_name:"",
      problem_happen:"",
      main_appeal:"",
      street_id:"",
      problem_id:"",
      show: false,
      show1: false,
      show2: false,
      show3: false,
      columns: ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华", "衢州"],
      columns1: ["杭州", "宁波", "温州", "绍兴", "湖州", "嘉兴", "金华", "衢州"],
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      minDate1: new Date(2020, 0, 1),
      maxDate1: new Date(2025, 10, 1),
      currentDate1: new Date(),
      id:"",
      myslh: sessionStorage.getItem('getslh'),
    };
  },
  mounted() {
    let id = this.$route.query.id;
    console.log(id);
    this.id = id
    let that = this;
    this.$get("/front/petition/ae", {id: id})
      .then(function (res) {
        console.log(res);
        if (!res.agent_name) {
          res.agent_name = "暂无";
        }
        if (!res.agent_team_name) {
          res.agent_team_name = "暂无";
        }
        if (res.status==1||res.status==3) {
          that.status = 1
        }else{
          that.status = 2
        }
        that.list = res;
        that.name=res.name
        that.people_number=res.people_number
        that.id_card=res.id_card
        that.phone=res.phone
        that.registered_unit=res.registered_unit
        that.petition_date=res.petition_date
        that.registration_date=res.registration_date
        that.petitioner_address=res.petitioner_address
        that.main_appeal=res.main_appeal
        that.problem_happen=res.cun_name
        that.street_name=res.street_name
        that.street_id=res.street_id
        that.problem_id=res.problem_happen
        console.log(that.list);
      })
      .catch(function (err) {
        console.log(err);
      });
    
    this.$get("/front/street/fenji")
      .then(function (res) {
        that.columns = res[0].street;
        console.log(that.columns);
      })
      .catch(function (err) {
        console.log(err);
      });
    
    this.$get("/front/street/list")
      .then(function (res) {
        that.columns1 = res;
        that.columns1.push({name:"椒江区信访局"})
        console.log(that.columns1);
      })
      .catch(function (err) {
        console.log(err);
      });
  },
  methods:{
     showPopup() {
      this.show = true;
    },
    del(){
      let id = this.id
    },
    editor(){
      let id = this.id
      this.$router.push({
        path: "/editor",
        query: {
          id: id,
        },
      });
    },
    onConfirm(value, index) {
      // console.log(this.columns[index].id)
      this.show = false;
      this.problem = value;
      // this.problem = this.columns[index].name;
      this.street_id = this.columns[index[0]].id;
      this.problem_id = this.columns[index[0]].children[index[1]].id;
      this.street_name = this.columns[index[0]].name;
      this.problem_happen = this.columns[index[0]].children[index[1]].name;
      console.log(this.problem_id)
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel() {
      this.show = false;
      // Toast("取消");
    },
    showPopup1() {
      this.show1 = true;
    },
    showPopup2() {
      this.show2 = true;
    },
     showPopup3() {
      this.show3 = true;
    },
    onConfirm1(value, index) {
      this.show1 = false;
      console.log(value);
      let xf_time =
        value.toLocaleDateString().replace(/\//g, "-") +
        " " +
        value.toTimeString().substr(0, 8);
      xf_time = xf_time.split(" ");
      console.log(xf_time);
      this.petition_date = xf_time[0];
    },
    onCancel1() {
      this.show1 = false;
    },
    onConfirm2(value, index) {
      this.show2 = false;
      console.log(value);
      let xf_time =
        value.toLocaleDateString().replace(/\//g, "-") +
        " " +
        value.toTimeString().substr(0, 8);
      xf_time = xf_time.split(" ");
      console.log(xf_time);
      this.registration_date = xf_time[0];
    },
    onCancel2() {
      this.show2 = false;
    },
    onConfirm3(value, index) {
      console.log(value)
      this.show3 = false;
      this.registered_unit = value.name;
      console.log(this.registered_unit)
      // Toast(`当前值：${value}, 当前索引：${index}`);
    },
    onCancel3() {
      this.show3 = false;
    },
    from() {
      let that = this;
      let data1 = {};
      if (!this.name) {
        Dialog({ message: "请填写信访人名称" });
        return;
      }
      if (!this.people_number) {
        Dialog({ message: "请填写人数" });
        return;
      }
      if (!this.id_card) {
        Dialog({ message: "请填写证件号码" });
        return;
      }
      // if (!this.assign) {
      //   Dialog({ message: "请填写交办编号" });
      //   return
      // }
      if (!(/^1[34578]\d{9}$/.test(this.phone))) {
        Dialog({ message: "请填写正确的电话号码" });
        return;
      }
      if (!this.registered_unit) {
        Dialog({ message: "请填写登记单位" });
        return;
      }
      if (!this.petition_date) {
        Dialog({ message: "请选择信访日期" });
        return;
      }
      if (!this.registration_date) {
        Dialog({ message: "请选择登记日期" });
        return;
      }
      if (!this.petitioner_address) {
        Dialog({ message: "请填写信访人地址" });
        return;
      }
      if (!this.street_name) {
        Dialog({ message: "请填写问题发生地" });
        return;
      }
      if (!this.main_appeal) {
        Dialog({ message: "请填写主要诉求" });
        return;
      }
      console.log(this.id)
      data1.id = this.id
      data1.name = this.name;
      data1.people_number = this.people_number;
      data1.id_card = this.id_card;
      // data1.assign_number = this.assign;
      data1.phone = this.phone;
      data1.registered_unit = this.registered_unit;
      data1.petition_date = this.petition_date;
      data1.registration_date = this.registration_date;
      // data1.visit_number = this.serial;
      data1.petitioner_address = this.petitioner_address;
      data1.problem_happen = this.problem_id;
      data1.street_id = this.street_id 
      data1.main_appeal = this.main_appeal;
      console.log(data1);
      // let data2 = JSON.stringify(data1);
      // console.log(data2);
      this.$post("/front/petition/ae", data1)
        .then(function (res) {
          console.log(res);
          if (res.status == 1) {
            Toast.success("提交成功");
            that.$router.push("/plan");
          } else {
            //  Dialog({ message: "提交失败，请检查网络" });
            Toast.fail("提交失败，请检查网络");
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    },
  }
};
</script>
<style scoped>
p {
  margin: 0;
}
.content {
  padding: 0px;
  overflow: hidden;
  z-index: 999;
  padding-bottom: 20px;
  /* top: -20px; */
  /* position: relative; */
  /* border-top-left-radius: 15px; */
  /* border-top-right-radius: 15px; */
}

.content li {
  /* padding: 0 15px; */
  height: 47px;
  border-bottom: 2px solid whitesmoke;
}

.content li .txt {
  /* color: #616161; */
  width: 100px;
  font-size: 14px;
  color: #fff;
  float: left;
  height: 45px;
  line-height: 45px;
  font-weight: 500;
  text-align: left;
  top: 0px;
  box-sizing: border-box;
  padding-left: 15px;
  background-color: #549ee7;
}

.content li .c_txt {
  color: #8A8A8A;
  text-align: right;
  float: left;
  width: calc(100% - 101px);
  height: 45px;
  line-height: 45px;
  box-sizing: border-box;
  padding-right: 15px;
  border: 1px solid #76c9ca;
  background-color: #f3f6fb;
  margin-left: 1px;
}

.text {
  font-size: 14px;
  color: #fff;
  height: 45px;
  line-height: 45px;
  overflow: hidden;
  font-weight: 500;
  padding: 0 15px;
  background-color: #549ee7;
}

.c_text {
  color: #8A8A8A;
  border-bottom: 2px solid whitesmoke;
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #76c9ca;
  margin: 1px 0;
  background-color: #f3f6fb;
}
.c_text1 {
  padding: 10px 15px;
  color: #8A8A8A;
  border: 1px solid #76c9ca;
  margin: 1px 0;
  background-color: #f3f6fb;
}
.state {
  padding: 10px 15px;
  border-bottom: 2px solid whitesmoke;
}

.state dt {
  font-size: 14px;
}

.state dd {
  padding: 10px 15px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.dian {
  width: 15px;
  height: 15px;
  background-color: #9c9c9c;
  border-radius: 50%;
  line-height: 15px;
  color: white;
  text-align: center;
}

.state dd div {
  width: 15px;
  float: left;
}

.dian::before {
  position: relative;
  top: 0.4px;
  right: 0.5px;
}

.line-1 {
  width: calc(52% - 35px);
  border: 1px dashed #9c9c9c;
  float: left;
  margin: 7px 1px;
  position: relative;
}

.state_txt {
  color: #9c9c9c;
  width: 100px;
  position: relative;
  left: -14px;
  margin-top: 8px;
}

.dian_on {
  background-color: #3b8ee2;
}

.line_on {
  border: 1px dashed #3b8ee2;
}

.on {
  color: #3b8ee2;
}

.introduce {
  color: #9c9c9c;
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: 5px;
  float: right;
}

.accessory {
  padding: 10px 15px;
  border-bottom: 2px solid whitesmoke;
}

.accessory dt {
  font-size: 14px;
}

.accessory dd {
  overflow: hidden;
  padding-top: 10px;
}

.accessory dd div {
  width: calc(25% - 7.5px);
  padding-top: calc(25% - 7.5px);
  float: left;
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;
}
.accessory dd div:nth-child(4n) {
  margin-right: 0;
}

.accessory dd div img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.tijiao{
  margin-top: 20px;
  width: 80%;
  margin-left: 10%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #3b8ee2;
  color: white;
  border-radius: 3px;
}
.dibu{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  line-height: 50px;
  border-top: 1px solid whitesmoke;
}
.dibu p{
  flex: 1;
  background-color: #3b8ee2;
  border-right: 1px solid white;
  text-align: center;
  color: white;
}

.old p{
  font-size: 17px !important;
}

.old input{
  font-size: 17px !important;
}

.old .content li .txt {
  width: 120px;
}

.old .state dt{
  font-size: 17px !important;
}

.old .content li .c_txt{
  width: calc(100% - 121px);
}

.old .tijiao{
  font-size: 17px;
  height: 50px;
  line-height: 50px;
}

</style>